import React, { useState } from "react";

import c from "classnames";
import Link from "next/link";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Image from "next/image";

import { ViewUser } from "@/salesHelpers/user";
import { useUserDataStore } from "@/salesStore/UserDataStore";
import IconMenu from "@/salesComponents/icons/iconMenu.svg";
import IconExpand from "@/salesComponents/icons/iconExpand.svg";
import { permissionLevel } from "@/salesHelpers/helperFunctions";
import { ProfilePic } from "@/salesComponents/elements/profilePic";
import { Button } from "@/salesComponents/elements/button";
import IconDashboard from "@/salesComponents/icons/iconDashboard.svg";
import IconCalendar from "@/salesComponents/icons/iconCalendar.svg";
import IconList from "@/salesComponents/icons/iconList.svg";
import IconShift from "@/salesComponents/icons/iconShift.svg";
import IconDirectory from "@/salesComponents/icons/iconDirectory.svg";
import IconLeads from "@/salesComponents/icons/iconLeads.svg";
import IconInvoice from "@/salesComponents/icons/iconInvoice.svg";
import IconIncentive from "@/salesComponents/icons/iconIncentive.svg";
import IconLeaderboard from "@/salesComponents/icons/iconLeaderboard.svg";
import IconReports from "@/salesComponents/icons/iconReports.svg";
import IconSidebarClose from "@/salesComponents/icons/iconSidebarClose.svg";
import IconSidebarOpen from "@/salesComponents/icons/iconSidebarOpen.svg";

import Styles from "./sidebar.module.scss";

type Props = {
	page: string;
	sidebarOpen: boolean;
	setSidebarOpen: (value: boolean) => void;
};

export const Sidebar = ({ page, sidebarOpen, setSidebarOpen }: Props) => {
	const [userOpen, setUserOpen] = useState(false);
	const [openMobile, setOpenMobile] = useState(false);

	const router = useRouter();

	const setActiveUser = useUserDataStore((state) => state.setActiveUser);

	// SESSION
	const { data: session } = useSession();

	return (
		<div
			className={c(Styles.sidebar, {
				[Styles.isClosed]: !sidebarOpen,
				[Styles.mobileOpen]: openMobile,
			})}
		>
			<div className={Styles.logoHolder}>
				<div
					className={Styles.burger}
					onClick={() => setOpenMobile(!openMobile)}
				>
					<IconMenu />
				</div>
				<Link href="/dashboard">
					<a className={Styles.logo}>Pure Pet Food</a>
				</Link>
			</div>
			<nav className={c(Styles.nav)}>
				<div className={Styles.catTitle}>Home</div>
				<ul>
					<li>
						<Link href="dashboard">
							<a
								className={c(Styles.navLink, {
									[Styles.active]: page === "dashboard",
								})}
							>
								<IconDashboard />
								Dashboard
							</a>
						</Link>
					</li>
				</ul>
				<div className={Styles.catTitle}>Sales</div>
				<ul>
					<li>
						<Link href="leads">
							<a
								className={c(Styles.navLink, {
									[Styles.active]: page === "leads",
								})}
							>
								<IconLeads />
								Leads
							</a>
						</Link>
					</li>
					<li>
						<Link href="leaderboards">
							<a
								className={c(Styles.navLink, {
									[Styles.active]: page === "leaderboards",
								})}
							>
								<IconLeaderboard />
								Leaderboards
							</a>
						</Link>
					</li>
					{permissionLevel(session?.user.role) > 2 && (
						<li>
							<Link href="incentives">
								<a
									className={c(Styles.navLink, {
										[Styles.active]: page === "incentives",
									})}
								>
									<IconIncentive />
									Incentives
								</a>
							</Link>
						</li>
					)}
				</ul>
				<div className={Styles.catTitle}>Events</div>
				<ul>
					<li>
						<Link href="events">
							<a
								className={c(Styles.navLink, {
									[Styles.active]: page === "events",
								})}
							>
								<IconCalendar />
								Calendar
							</a>
						</Link>
					</li>
					{permissionLevel(session?.user.role) > 2 && (
						<li>
							<Link href="planner">
								<a
									className={c(Styles.navLink, {
										[Styles.active]: page === "planner",
									})}
								>
									<IconList />
									Planner
								</a>
							</Link>
						</li>
					)}
					<li>
						<Link href="shifts">
							<a
								className={c(Styles.navLink, {
									[Styles.active]: page === "shifts",
								})}
							>
								<IconShift />
								Shifts
							</a>
						</Link>
					</li>
				</ul>
				{permissionLevel(session?.user.role) > 1 && (
					<>
						<div className={Styles.catTitle}>Finance</div>
						<ul>
							<li>
								<Link href="reports">
									<a
										className={c(Styles.navLink, {
											[Styles.active]: page === "reports",
										})}
									>
										<IconReports />
										Reports
									</a>
								</Link>
							</li>
							{permissionLevel(session?.user.role) > 5 && (
								<li>
									<Link href="invoices">
										<a
											className={c(Styles.navLink, {
												[Styles.active]: page === "invoices",
											})}
										>
											<IconInvoice />
											Invoices
										</a>
									</Link>
								</li>
							)}
						</ul>
					</>
				)}
				<div className={Styles.catTitle}>Team</div>

				<ul>
					<li>
						<Link href="directory">
							<a
								className={c(Styles.navLink, {
									[Styles.active]: page === "directory",
								})}
							>
								<IconDirectory />
								Directory
							</a>
						</Link>
					</li>
				</ul>
			</nav>
			<div
				className={Styles.sideBarClose}
				onClick={() => setSidebarOpen(!sidebarOpen)}
			>
				{sidebarOpen ? <IconSidebarClose /> : <IconSidebarOpen />}
			</div>
		</div>
	);
};
