import { useState } from "react";

import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import c from "classnames";

import { Button } from "@/salesComponents/elements/button";
import { ProfilePic } from "@/salesComponents/elements/profilePic";
import { ViewUser } from "@/salesHelpers/user";
import { useUserDataStore } from "@/salesStore/UserDataStore";
import { useSalesDataStore } from "@/salesStore/SalesDataStore";
import IconExpand from "@/salesComponents/icons/iconExpand.svg";

import Styles from "./header.module.scss";

export const Header = ({
	title,
	sidebarOpen,
}: {
	title: string;
	sidebarOpen: boolean;
}) => {
	const [userOpen, setUserOpen] = useState(false);

	// SESSION
	const { data: session } = useSession();

	const router = useRouter();

	const setActiveUser = useUserDataStore((state) => state.setActiveUser);

	const todaysSales = useSalesDataStore((state) => state.todaysSales);

	return (
		<div className={c(Styles.header, { [Styles.headerBig]: !sidebarOpen })}>
			<h2 className="mobileSize">{title}</h2>
			<nav className={Styles.nav}>
				<ul>
					<li className={Styles.todaysSales}>Today's sales: {todaysSales}</li>
					<li className={Styles.sellBtn}>
						<Button
							use="link"
							link="/sales/"
							type="primary"
							text="Start"
							size="small"
						></Button>
					</li>
					<li className={c(Styles.withDropdown, { [Styles.isOpen]: userOpen })}>
						<span onClick={() => setUserOpen(!userOpen)}>
							<ProfilePic
								firstName={session?.user?.first_name}
								lastName={session?.user?.last_name}
								pic={session?.user?.profile_picture}
							/>
							<IconExpand />
						</span>
						<ul className={Styles.dropdown}>
							<li>
								<button
									type="button"
									onClick={() => {
										ViewUser(session?.user.id || 0, router, setActiveUser);
										setUserOpen(!userOpen);
									}}
									className={c(Styles.dropNavLink)}
								>
									My profile
								</button>
							</li>
							<li>
								<button
									className={c(Styles.dropNavLink)}
									onClick={() => {
										signOut();
										setUserOpen(!userOpen);
									}}
								>
									Logout
								</button>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
		</div>
	);
};
