import React from "react";

import c from "classnames";
import Link from "next/link";

import IconView from "@/salesComponents/icons/iconView.svg";
import IconLeft from "@/salesComponents/icons/iconLeft.svg";
import IconRefresh from "@/salesComponents/icons/iconRefresh.svg";
import IconAdd from "@/salesComponents/icons/iconAdd.svg";

import Styles from "./button.module.scss";

type Props = {
	type: "primary" | "secondary" | "tertiary" | "negative" | "text";
	text?: string;
	action?: (value: any) => void;
	use: "form" | "action" | "link";
	link?: string;
	icon?: string;
	sizing?: "full" | "relative";
	size?: "small" | "medium" | "large";
	loading?: boolean;
	className?: string;
	target?: string;
	disabled?: boolean;
};

export const Button = ({
	type,
	text,
	action,
	use,
	link,
	icon,
	sizing = "full",
	size = "medium",
	loading = false,
	className,
	target = "_self",
	disabled = false,
	...props
}: Props) => {
	let theIcon = null;
	if (icon === "view") {
		theIcon = <IconView />;
	} else if (icon === "back") {
		theIcon = <IconLeft />;
	} else if (icon === "add") {
		theIcon = <IconAdd />;
	} else if (icon === "refresh") {
		theIcon = <IconRefresh />;
	}

	return (
		<>
			{use === "link" && link ? (
				<Link href={link}>
					<a
						className={c(Styles[type], Styles[sizing], Styles[size], className)}
						target={target}
					>
						{theIcon}
						{text}
					</a>
				</Link>
			) : (
				<button
					type={use === "form" ? "submit" : "button"}
					className={c(
						Styles[type],
						className,
						{
							[Styles.formButton]: use === "form",
							[Styles.isLoading]: loading,
						},
						Styles[sizing],
						Styles[size]
					)}
					onClick={(val) => !loading && action && action(val)}
					disabled={disabled}
					{...props}
				>
					{theIcon}
					{text}
				</button>
			)}
		</>
	);
};
