import { create } from "zustand";

import { Subscription } from "@/salesTypes/subscriptions";

type SalesDataStore = {
	subscriptions: Subscription[];
	setSubscriptions: (newSubscriptios: Subscription[]) => void;
	updateSubscriptions: (newSubscription: Subscription[]) => void;
	todaysSales: number;
	setTodaysSales: (sales: number) => void;
};

export const useSalesDataStore = create<SalesDataStore>((set) => ({
	subscriptions: [],
	setSubscriptions: (subscriptions: Subscription[]) =>
		set({ subscriptions: subscriptions }),
	updateSubscriptions: (subs: Subscription[]) => {
		set((state) => {
			const newSubscriptions = state.subscriptions.filter(
				(sub) => !subs.map((thisSub) => thisSub.id).includes(sub.id)
			);
			return {
				subscriptions: [...newSubscriptions, ...subs],
			};
		});
	},
	todaysSales: 0,
	setTodaysSales: (sales: number) => set({ todaysSales: sales }),
}));
