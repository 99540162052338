import React, { useState } from "react";

import c from "classnames";
import { Toaster } from "react-hot-toast";

import Styles from "./layout.module.scss";
import { Sidebar } from "./sidebar/sidebar";
import { Header } from "./header/header";

type Props = {
	children: React.ReactNode;
	page: string;
	title: string;
};

export const Layout = ({ children, page, title }: Props) => {
	const [sidebarOpen, setSidebarOpen] = useState(true);

	return (
		<div className={c(Styles.body)}>
			<Toaster
				position="bottom-right"
				containerClassName={Styles.toastCont}
				toastOptions={{
					// Define default options
					className: Styles.toast,
					duration: 5000,
					success: {
						iconTheme: {
							primary: "#274c46",
							secondary: "white",
						},
					},
				}}
			/>
			<Sidebar
				page={page}
				sidebarOpen={sidebarOpen}
				setSidebarOpen={setSidebarOpen}
			/>
			<Header title={title} sidebarOpen={sidebarOpen} />
			<div
				id={Styles.mainContent}
				className={c(Styles.mainContent, {
					[Styles.mainContentBig]: !sidebarOpen,
				})}
			>
				{/* <Header page={page} /> */}
				<div className="container containerLarge">
					{/* <div className={Styles.logo}>Pure Pet Food</div> */}
					{children}
				</div>
			</div>
		</div>
	);
};
